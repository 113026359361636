(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-subscription/assets/javascripts/competition-subscription.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-subscription/assets/javascripts/competition-subscription.js');
"use strict";


const {
  useEffect
} = React;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const {
  updateCompetition
} = svs.components.marketplaceCompetition.services.actions.competitionActions;
const {
  useDispatch
} = ReactRedux;
const CompetitionSubscription = _ref => {
  let {
    competitionId
  } = _ref;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!competitionId) {
      return;
    }
    let unsubscribe;
    const subscribeToComp = async () => {
      unsubscribe = await competitionHandler().subscribe(competitionId, competition => {
        dispatch(updateCompetition(competition.getSnapshot()));
      });
    };
    subscribeToComp();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [competitionId, dispatch]);
  return null;
};
setGlobal('svs.components.marketplaceCompetition.competitionSubscription.CompetitionSubscription', CompetitionSubscription);

 })(window);